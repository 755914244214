

'use client'

import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Cookies from "universal-cookie";
import axios from "axios"
import { useState } from 'react';
const cookies = new Cookies();
function App() {
  const [data,setData] = useState([])
  useEffect(()=>{
    const id_user = cookies.get("idUser")
    const username = cookies.get("dataUser")
    const token = cookies.get("u-nkt")
    axios.post(`https://api.meylendra.com:1998/auth/keeplogin`,{
            username,
            token,
            data:true,
            id:id_user
    }).then((res)=>{
        if(res.data.length > 0 ){
            setData(res.data)
            localStorage.setItem("data",JSON.stringify(res.data))
        }
    })
  },[])


  const dataUser = typeof window !== 'undefined' ? localStorage.getItem("data") : null;
  const tawkMessengerRef = useRef();
  if(dataUser){
    let parseData = JSON.parse(dataUser)[0]
    
  
    const onLoad = () => {
      if (tawkMessengerRef.current) {
          tawkMessengerRef.current.setAttributes({
            name : parseData.username,
            email : parseData.email
          });
        }
      
    };
    const onBeforeload=()=>{
      tawkMessengerRef.current.maximize();
    }
   
    return (
      <div>
          <TawkMessengerReact
                   propertyId="658cdaba07843602b8060e7f"
                   widgetId="1hin3cqf3"
                   onLoad={onLoad}
                   onBeforeLoad={onBeforeload}
                   ref={tawkMessengerRef}/>
      </div>
     )
  }else{
    let parseData = data[0]
    const onLoad = () => {
      if (tawkMessengerRef.current) {
          tawkMessengerRef.current.setAttributes({
            name : parseData.username,
            email : parseData.email
          });
        }
      
    };
    const onBeforeload=()=>{
      tawkMessengerRef.current.maximize();
    }
   
    return (
      <div>
          <TawkMessengerReact
                   propertyId="658cdaba07843602b8060e7f"
                   widgetId="1hin3cqf3"
                   onLoad={onLoad}
                   onBeforeLoad={onBeforeload}
                   ref={tawkMessengerRef}/>
      </div>
     )
  }
  
  
}
export default App;